html,
body {
  width: 100%;
  min-height: 100%;
}

svg {
  fill: currentColor;
  pointer-events: none;
}

.btn > svg {
  pointer-events: none;
}

.btn-sm > svg {
  width: 12px;
  height: 12px;
}

.table td,
.table th {
  vertical-align: middle;
}

.toast-container {
  position: fixed;
  top: 30px;
  width: 60%;
  z-index: 100000;
  margin: 0px 20%;
  animation: toast-drop-in 500ms ease-in-out;
}

@keyframes toast-drop-in {
  0% {
    top: 0px;
  }
  100% {
    top: 30px;
  }
}

.overlay {
  z-index: 1;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.border-0-when-last:last-of-type {
  border: none !important;
}
